form{
  display: flex;
  flex-direction: column;
}

.mt3{
  padding-bottom: 1rem;
  outline: 2;
  width: 30rem;
}

.input-error{
  color: brown;
  padding-left: 0.3rem;
  font-size: 0.8rem;
}

.submit-error{
  color: brown;
}

.sheet{
  max-width: 500;
  border-radius: md;
  padding: 3;
  box-shadow: lightblue;
}
