.page {
    box-sizing: border-box;
    padding: 2rem 10rem;
    min-height: 100vh;
    background-color: #F7F7F8;
}

.header {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header > *:first-child {
    margin: 0;
    flex: auto;
}


.content {
    display: flex;
}

.content > *+* {
    margin-left: 1rem;
}

